<template>
  <div>
    <h3>{{ $t("overview.finance.takings.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navigate()">
        {{ $t("overview.finance.takings.missing") }}
      </div>
      <div class="bold">{{ missingTakings }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TakingsDashlet",
  methods: {
    navigate() {
      this.$router.push({ path: "/finances/takings/add" });
    },
  },
  computed: {
    missingTakings() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return el.isOver && el.isPublished;
          }).length;
    },
    ...mapGetters({
      events: "events/list",
    }),
  },
};
</script>
