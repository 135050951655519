<template>
  <vca-card class="shadowed">
    <h2>{{ $t("overview.dashboard.network") }}</h2>
    <vca-row>
      <vca-column>
        <ActiveDashlet />
        <div><hr /></div>
        <EventConfirmationDashlet />
        <EventApplicationDashlet />
        <div><hr /></div>
        <EmailDraftDashlet />
      </vca-column>
    </vca-row>
  </vca-card>
</template>
<script>
import ActiveDashlet from "@/components/overview/dashboard/users/ActiveDashlet";
import EventConfirmationDashlet from "@/components/overview/dashboard/events/EventConfirmationDashlet";
import EventApplicationDashlet from "@/components/overview/dashboard/events/EventApplicationDashlet";
import EmailDraftDashlet from "@/components/overview/dashboard/emails/EmailDraftDashlet";
export default {
  name: "NetworkOverview",
  components: {
    ActiveDashlet,
    EventApplicationDashlet,
    EventConfirmationDashlet,
    EmailDraftDashlet,
  },
};
</script>
