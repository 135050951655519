<template>
  <div>
    <h3>{{ $t("overview.events.application.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navigateOpen()">
        {{ $t("overview.events.application.open") }}
      </div>
      <div class="bold">{{ applicationOpen }}</div>
    </vca-row>
    <vca-row>
      <div class="clickable" @click="navigateMissing()">
        {{ $t("overview.events.application.missing") }}
      </div>
      <div class="bold">{{ applicationMissing }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EventApplicationDashlet",
  methods: {
    navigateOpen() {
      this.$router.push({ path: "/events" }).then(() => {
        this.$store.state.events.pg.pagination.filterObject.only_apply = true;
      });
    },
    navigateMissing() {
      this.$router.push({ path: "/events" }).then(() => {
        this.$store.state.events.pg.pagination.filterObject.only_apply = true;
        this.$store.state.events.pg.pagination.filterObject.missing_applications = true;
      });
    },
  },
  computed: {
    applicationOpen() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return (
              !(
                new Date() < new Date(el.application.start_date * 1000) ||
                new Date() > new Date(el.application.end_date * 1000)
              ) && el.event_state.state == "published"
            );
          }).length;
    },
    applicationMissing() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return (
              !(
                new Date() < new Date(el.application.start_date * 1000) ||
                new Date() > new Date(el.application.end_date * 1000)
              ) &&
              el.event_state.state == "published" &&
              el.application.supporter_count >
                (el.participations
                  ? el.participations.filter((pel) => {
                      return pel.status == "confirmed";
                    }).length
                  : Number.MAX_SAFE_INTEGER)
            );
          }).length;
    },
    ...mapGetters({
      events: "events/list",
    }),
  },
};
</script>
