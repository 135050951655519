<template>
  <vca-card class="shadowed">
    <h2>{{ $t("overview.dashboard.operation") }}</h2>

    <vca-row>
      <vca-column>
        <EventApplicationDashlet />
        <EventConfirmationDashlet />
      </vca-column>
    </vca-row>
  </vca-card>
</template>
<script>
import EventConfirmationDashlet from "@/components/overview/dashboard/events/EventConfirmationDashlet";
import EventApplicationDashlet from "@/components/overview/dashboard/events/EventApplicationDashlet";
export default {
  name: "EventsOverview",
  components: { EventApplicationDashlet, EventConfirmationDashlet },
};
</script>
