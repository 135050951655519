<template>
  <vca-card class="shadowed">
    <h2>{{ $t("overview.dashboard.finance") }}</h2>
    <vca-row>
      <vca-column>
        <TakingsDashlet />
      </vca-column>
    </vca-row>
  </vca-card>
</template>
<script>
import TakingsDashlet from "@/components/overview/dashboard/finances/TakingsDashlet";
export default {
  name: "FinanceOverview",
  components: { TakingsDashlet },
};
</script>
