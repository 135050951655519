<template>
  <div>
    <h3>{{ $t("overview.users.nvm.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navNvm">
        {{ $t("overview.users.nvm.confirmed") }}
      </div>
      <div class="bold">{{ nvmState("confirmed") }}</div>
    </vca-row>
    <vca-row>
      <div>{{ $t("overview.users.nvm.expired") }}</div>
      <div class="bold">{{ nvmState("expired") }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NvmDashlet",
  methods: {
    navNvm() {
      this.$router.push({ path: "/users" }).then(() => {
        this.$store.state.users.pg.pagination.filterObject.nvm = true;
      });
    },
    nvmState(state) {
      return !this.users
        ? ""
        : this.users.filter((el) => {
            return el.nvm.status == state;
          }).length;
    },
  },
  computed: {
    ...mapGetters({
      users: "users/list",
    }),
  },
};
</script>
