<template>
  <div>
    <h3>{{ $t("overview.users.header") }}</h3>
    <vca-row>
      <div>{{ $t("overview.users.total") }}</div>
      <div class="bold">{{ users.length }}</div>
    </vca-row>
    <vca-row>
      <div>{{ $t("overview.users.confirmed") }}</div>
      <div class="bold">{{ confirmedUsers }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UsersDashlet",
  computed: {
    confirmedUsers() {
      return !this.users
        ? ""
        : this.users.filter((el) => {
            return el.confirmed;
          }).length;
    },
    ...mapGetters({
      users: "users/list",
    }),
  },
};
</script>
