<template>
  <div>
    <h3>{{ $t("overview.events.confirmation.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navigate('requested')">
        {{ $t("overview.events.confirmation.total") }}
      </div>
      <div class="bold">{{ confirmationOpen }}</div>
    </vca-row>
    <vca-row>
      <div class="clickable" @click="navigate('requested_crew')">
        {{ $t("overview.events.confirmation.crew") }}
      </div>
      <div class="bold">{{ confirmationOpenAsp }}</div>
    </vca-row>
    <vca-row>
      <div class="clickable" @click="navigate('requested_internal')">
        {{ $t("overview.events.confirmation.employee") }}
      </div>
      <div class="bold">{{ confirmationOpenEmployee }}</div>
    </vca-row>
    <vca-row>
      <div class="clickable" @click="navigate('created')">
        {{ $t("overview.events.created") }}
      </div>
      <div class="bold">{{ confirmationDraft }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EventConfirmationDashlet",
  methods: {
    navigate(state) {
      this.$router.push({ path: "/events" }).then(() => {
        this.$store.state.events.pg.pagination.filterObject.only_apply = false;
        this.$store.state.events.pg.pagination.filterObject.event_state = [
          state,
        ];
      });
    },
  },
  computed: {
    confirmationOpen() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return el.event_state.state == "requested";
          }).length;
    },
    confirmationOpenAsp() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return (
              el.event_state.crew_confirmation == "" &&
              el.event_state.state == "requested"
            );
          }).length;
    },
    confirmationOpenEmployee() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return (
              el.event_state.internal_confirmation == "" &&
              el.event_state.state == "requested"
            );
          }).length;
    },
    confirmationDraft() {
      return !this.events
        ? ""
        : this.events.filter((el) => {
            return (
              el.event_state.state == "created" &&
              (this.hasSystemPermission() ||
                (this.hasPoolPermission(this.poolEventPermissions) &&
                  this.user.crew &&
                  el.crew &&
                  this.user.crew.crew_id == el.crew.id))
            );
          }).length;
    },
    ...mapGetters({
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
      events: "events/list",
    }),
  },
};
</script>
