<template>
  <vca-card class="shadowed">
    <h2>{{ $t("overview.dashboard.education") }}</h2>
  </vca-card>
</template>
<script>
export default {
  name: "EducationOverview",
};
</script>
