<template>
  <div>
    <h3>{{ $t("overview.users.active.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navigate('requested')">
        {{ $t("overview.users.active.requests") }}
      </div>
      <div class="bold">{{ activeState("requested") }}</div>
    </vca-row>
    <vca-row>
      <div class="clickable" @click="navigate('confirmed')">
        {{ $t("overview.users.active.confirmed") }}
      </div>
      <div class="bold">{{ activeState("confirmed") }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ActiveDashlet",
  methods: {
    navigate(state) {
      this.$router.push({ path: "/users" }).then(() => {
        this.$store.state.users.pg.pagination.filterObject.status = state;
      });
    },
    activeState(state) {
      return !this.users
        ? ""
        : this.users.filter((el) => {
            return el.active.status == state;
          }).length;
    },
  },
  computed: {
    ...mapGetters({
      users: "users/list",
    }),
  },
};
</script>
