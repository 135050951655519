<template>
  <vca-card class="shadowed">
    <h2>{{ $t("overview.dashboard.admin") }}</h2>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AdminOverview",
  computed: {
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
