<template>
  <div>
    <h3>{{ $t("overview.emails.draft.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navDraft('requested')">
        {{ $t("overview.emails.draft.total") }}
      </div>
      <div class="bold">{{ emailCount }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EmailDraftDashlet",
  methods: {
    navDraft() {
      this.$router.push({ path: "/emails/drafts" });
    },
  },
  computed: {
    ...mapGetters({
      emails: "emails/mailbox/draft",
    }),
    emailCount() {
      return this.emails ? this.emails.length : 0;
    },
  },
};
</script>
