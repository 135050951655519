<template>
  <vca-card class="shadowed dashlet-content">
    <h2>{{ $t("overview.dashboard.employee") }}</h2>

    <vca-row>
      <vca-column>
        <UsersDashlet />
      </vca-column>
      <div></div>
    </vca-row>
    <vca-row>
      <vca-column>
        <ActiveDashlet />
      </vca-column>
      <vca-column>
        <NvmDashlet />
      </vca-column>
    </vca-row>
    <hr />

    <vca-row>
      <vca-column>
        <CrewsDashlet />
      </vca-column>
      <div></div>
    </vca-row>
    <vca-row>
      <vca-column>
        <ArtistsDashlet />
      </vca-column>
      <vca-column>
        <OrganizersDashlet />
      </vca-column>
    </vca-row>
    <hr />

    <vca-row>
      <vca-column>
        <EventConfirmationDashlet />
      </vca-column>
      <div></div>
    </vca-row>
  </vca-card>
</template>
<script>
import CrewsDashlet from "@/components/overview/dashboard/network/CrewsDashlet";
import ArtistsDashlet from "@/components/overview/dashboard/network/ArtistsDashlet";
import OrganizersDashlet from "@/components/overview/dashboard/network/OrganizersDashlet";
import UsersDashlet from "@/components/overview/dashboard/users/UsersDashlet";
import ActiveDashlet from "@/components/overview/dashboard/users/ActiveDashlet";
import NvmDashlet from "@/components/overview/dashboard/users/NvmDashlet";
import EventConfirmationDashlet from "@/components/overview/dashboard/events/EventConfirmationDashlet";
export default {
  name: "EmployeeOverview",
  components: {
    UsersDashlet,
    ActiveDashlet,
    NvmDashlet,
    CrewsDashlet,
    ArtistsDashlet,
    OrganizersDashlet,
    EventConfirmationDashlet,
  },
};
</script>
