<template>
  <div>
    <h3>{{ $t("overview.network.crews.header") }}</h3>
    <vca-row>
      <div class="clickable" @click="navCrews">
        {{ $t("overview.network.crews.total") }}
      </div>
      <div class="bold">{{ crews.length }}</div>
    </vca-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewDashlet",
  methods: {
    navCrews() {
      this.$router.push({ path: "/network/crews" });
    },
  },
  computed: {
    ...mapGetters({
      crews: "crews/list",
    }),
  },
};
</script>
