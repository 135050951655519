<template>
  <vca-card>
    <vca-card class="text-center shadowed">
      <h1>{{ $t("overview.header") }}</h1>
    </vca-card>
    <vca-row>
      <AdminOverview v-if="hasSystemPermission('admin')" />
      <EmployeeOverview v-if="hasSystemPermission('employee')" />
    </vca-row>
    <vca-row>
      <FinanceOverview v-if="hasPoolPermission('finance')" />
      <EventsOverview v-if="hasPoolPermission('operation')" />
      <NetworkOverview v-if="hasPoolPermission('network')" />
      <EducationOverview v-if="hasPoolPermission('education')" />
    </vca-row>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";
import AdminOverview from "@/components/overview/dashboard/AdminOverview";
import EmployeeOverview from "@/components/overview/dashboard/EmployeeOverview";

import FinanceOverview from "@/components/overview/dashboard/FinanceOverview";
import EventsOverview from "@/components/overview/dashboard/EventsOverview";
import NetworkOverview from "@/components/overview/dashboard/NetworkOverview";
import EducationOverview from "@/components/overview/dashboard/EducationOverview";
export default {
  name: "OverView",
  components: {
    AdminOverview,
    EmployeeOverview,
    EventsOverview,
    FinanceOverview,
    NetworkOverview,
    EducationOverview,
  },
  created() {
    if (this.hasSystemPermission()) {
      this.$store.dispatch({ type: "users/list" });
      this.$store.dispatch({ type: "events/list" });
      this.$store.dispatch({ type: "artists/list" });
      this.$store.dispatch({ type: "organizers/list" });
      this.$store.dispatch({ type: "crews/public" });
    }
    if (this.hasPoolPermission()) {
      if (this.hasPoolPermission("operation")) {
        this.$store.dispatch({ type: "events/list" });
      }
      if (this.hasPoolPermission("network")) {
        var id = {
          mailbox_id: this.user.crew.mailbox_id,
          user_id: this.user.id,
        };
        this.$store.commit("emails/mailbox/id", id);
        this.$store.dispatch("emails/mailbox/get");
        this.$store.dispatch({ type: "events/list" });
      }
      if (this.hasPoolPermission("finance")) {
        this.$store.dispatch({ type: "events/list" });
      }
      if (this.hasPoolPermission("education")) {
        this.$store.dispatch({ type: "events/list" });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
